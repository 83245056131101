import Vue from 'vue';
import Vuex from 'vuex';
import Cookies from 'js-cookie';
import VuexPersistence from 'vuex-persist';
import modules from './modules';

const vuexCookie = new VuexPersistence({
  key: 'vma',
  restoreState: (key) => {
    if (!Cookies.getJSON(key)) {
      Cookies.set(key, '{"auth": { "token":"", "user":{} }}')
    }
    return Cookies.getJSON(key)
  },
  saveState: (key, state) => Cookies.set(key, state, { expires: 3 }),
  modules: ['app', 'auth']
});

Vue.use(Vuex);

const store = new Vuex.Store({
  modules,
  plugins: [vuexCookie.plugin]
});

export default store;
