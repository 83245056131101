import Vue from 'vue';
import VueRouter from 'vue-router';
import NProgress from 'nprogress';
import _ from 'underscore';
import store from '@/store';
import * as routes from './routes';
import 'nprogress/nprogress.css';

Vue.use(VueRouter);
const router = new VueRouter({
  mode: 'history',
  linkActiveClass: 'active',
  base: process.env.BASE_URL,
  routes: _.toArray(routes).reduce((acc, val) => acc.concat(val), []),
});

// router guards
router.beforeEach((to, from, next) => {
  NProgress.start();
  const token = store.getters['auth/getAccessToken'];
  // const user = store.getters.getUser;
  const user = store.getters['auth/getUser']
  if (to.name !== 'login') {
    if (token) {
      if (to.meta && to.meta.roles && !to.meta.roles.includes(user.rol)) {
        next({ name: 'home' })
      } else {
        next();
      }
    } else {
      next({ name: 'login' });
    }
  } else if (token) {
    next({ name: 'home' });
  } else {
    next();
  }
});

router.afterEach(() => {
  NProgress.done();
});

export default router;
