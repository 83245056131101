import axios from 'axios';
import _ from 'underscore'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 50000,
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
});

const err = (error) => {
  const { status, data, statusText } = error.response;
  switch (status) {
    case 400:
      if (_.isArray(data.message) && data.message.length > 0) {
        window.VMA.$emit('SHOW_SNACKBAR', {
          show: true,
          text: `${data.message[0]}`,
          color: 'red',
        });
      } else {
        window.VMA.$emit('SHOW_SNACKBAR', {
          show: true,
          text: statusText + ' ' + data.message,
          color: 'red',
        });
      }
      break;
    // case 422:
    //   window.VMA.$emit('SHOW_SNACKBAR', {
    //     show: true,
    //     text: message,
    //     color: 'red',
    //   });
    //   break;
    case 401:
      window.VMA.$emit('AUTH_FAIELD');
      break;
    case 403:
      window.VMA.$emit('ACESS_DENIED');
      break;
    case 404:
      window.VMA.$emit('AUTH_FAIELD');
      break;
    case 500:
      window.VMA.$emit('SERVER_ERROR');
      break;
    default:
      break;
  }

  return Promise.reject(error);
};

// request interceptor

service.interceptors.request.use((config) => {
  // config.headers['Access-Control-Allow-Origin'] = 'http://localhost:3000';
  config.headers['Content-Type'] = 'application/json';
  return config;
}, err);

// response interceptor

service.interceptors.response.use(({ data }) => data, err);

export default service;
