import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import en from '@/locale/en';
import es from '@/locale/es';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { es, en },
    current: 'es',
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#692E42',
        secondary: '#424242',
        accent: '#875767',
        error: '#FF5252',
        info: '#a5818d',
        success: '#4CAF50',
        warning: '#FFC107',
        greenKalan: '#3c695b'
      },
    },
  },
});
