<template>
  <v-snackbars
    :objects.sync="messages"
    :timeout="timeout"
    transition="fade-transition"
  >
    <template v-slot:action="{ close, message, index }">
      <v-btn mini icon @click="onClose(close, message, index)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
    <template v-slot:default="{ message }">
      <v-layout
        align-center
        pr-4
        @click="click(message.onClick)"
        :class="{'snackbar-pointer': message.onClick ? true : false}"
      >
        <v-icon class="pr-3" dark large v-if="message.icon">{{ message.icon }}</v-icon>
        <v-layout column>
          <div>
            <strong>{{ message.title }}</strong>
          </div>
          <div>{{ message.body }}</div>
        </v-layout>
      </v-layout>
    </template>
  </v-snackbars>
</template>

<script>
import VSnackbars from 'v-snackbars'

export default {
  name: 'Snackbar',
  components: {
    'v-snackbars': VSnackbars
  },
  props: {
    bottom: Boolean,
    center: Boolean,
    right: Boolean,
    left: Boolean
  },
  data() {
    return {
      timeout: 10000,
      messages: [],
      timeouts: {}
    }
  },
  methods: {
    addMessage(item) {
      item.message.id = new Date().toISOString
      this.messages.push(item);
      if (item.message.onShown) {
        item.message.onShown()
      }
      if (item.message.onClose) {
        this.timeouts[item.message.id] = setTimeout(() => {
          window.clearTimeout(this.timeouts[item.message.id])
          item.message.onClose()
        }, item.timeout || this.timeout)
      }
    },
    onClose(close, message) {
      if (message.onClose) {
        window.clearTimeout(this.timeouts[message.id])
        message.onClose()
      }
      close()
    },
    click(fn) {
      if (fn) {
        fn()
      }
    },
  }
}
</script>

<style lang="scss">
  .v-snack__wrapper {
    opacity: 0.9;
    &:hover {
      opacity: 1;
    }
  }
  .snackbar-pointer {
    cursor: pointer;
  }
  .v-snack__action {
    align-self: start;
    margin-right: unset !important;
  }
</style>
