import { LayoutAuth, LayoutDefault } from "@/components/layouts";
import store from '@/store';

export const publicRoute = [
  {
    path: "*",
    component: () => import("@/views/error/NotFound.vue"),
  },
  {
    path: "/login",
    redirect: "/auth/login",
  },
  {
    path: "/auth",
    component: LayoutAuth,
    meta: {
      title: "Login",
    },
    redirect: "/auth/login",
    hidden: true,
    children: [
      {
        path: "login",
        name: "login",
        meta: {
          title: "Login",
        },
        component: () => import("@/views/Login.vue"),
      },
      {
        path: 'logout',
        name: 'logout',
        component: {
          beforeRouteEnter() {
            store.dispatch('auth/logout', null, { root: true })
          }
        }
      }
    ],
  },

  {
    path: "/404",
    name: "404",
    meta: {
      title: "Not Found",
    },
    component: () => import("@/views/error/NotFound.vue"),
  },

  {
    path: "/500",
    name: "500",
    meta: {
      title: "Server Error",
    },
    component: () => import("@/views/error/Error.vue"),
  },
];

export const protectedRoute = [
  {
    path: "/",
    component: LayoutDefault,
    meta: {
      title: "home",
      group: "apps",
      icon: "",
    },
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "home",
        meta: {
          title: "home",
          group: "apps",
          icon: "mdi-view-dashboard",
        },
        component: () => import("@/views/Home.vue"),
      },
      {
        path: "/users",
        name: "users",
        meta: {
          title: "users",
          group: "apps",
          icon: "mdi-account",
        },
        component: () => import("@/views/Users.vue"),
      },
      {
        path: "/clients",
        name: "clients",
        meta: {
          title: "Clientes",
          group: "apps",
          icon: "mdi-format-list-bulleted",
        },
        component: () => import("@/views/admin/Clients.vue"),
      },
      {
        path: "/base-classes",
        name: "baseClasses",
        meta: {
          title: "Clases Base",
          group: "apps",
          icon: "mdi-format-list-bulleted",
          roles: ['admin']
        },
        component: () => import("@/views/admin/BaseClass.vue"),
      },
      {
        path: "/object-types",
        name: "objectTypes",
        meta: {
          title: "Tipos de Objeto",
          group: "apps",
          icon: "mdi-format-list-bulleted",
          roles: ['admin']
        },
        component: () => import("@/views/admin/ObjectType.vue"),
      },
      {
        path: "/base-types",
        name: "baseTypes",
        meta: {
          title: "Tipos Base",
          group: "apps",
          icon: "mdi-format-list-bulleted",
          roles: ['admin']
        },
        component: () => import("@/views/admin/BaseType.vue"),
      },
    ],
  },
];
