<template>
  <v-navigation-drawer
    app
    class="app-drawer"
    :mini-variant.sync="mini"
    v-model="drawer"
    :width="drawerWidth"
    :dark="$vuetify.dark"
  >
    <v-toolbar color="primary" dark dense>
      <!-- <img :src="require('../assets/logo.svg')" height="36" alt="Kalan" /> -->
      <v-toolbar-title class="ml-0">
        <span class="hidden-sm-and-down">Murphy Pacífico</span>
      </v-toolbar-title>
    </v-toolbar>
    <div class="app-drawer__inner">
      <div>
        <v-subheader v-if="drawerWidth !== 64">
          {{ getUser.name }}
        </v-subheader>
      </div>
      <v-list :dense="drawerWidth !== 64" class="pa-0">
        <template v-for="(item, key) in computeMenu">
          <template v-if="item.children && item.children.length > 0">
            <v-list-group :key="key" no-action :to="item.path">
              <template v-slot:prependIcon>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" v-text="item.meta.icon" />
                  </template>
                  <span>
                    {{ $vuetify.lang.t('$vuetify.menu.' + item.meta.title) }}
                  </span>
                </v-tooltip>
              </template>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title
                    v-text="$vuetify.lang.t('$vuetify.menu.' + item.meta.title)"
                  />
                </v-list-item-content>
              </template>
              <v-list-item
                :class="drawerWidth === 64 ? 'pl-4' : ''"
                v-for="subItem in item.children"
                :key="subItem.name"
                :to="subItem.path"
                v-show="!subItem.meta.hiddenInMenu"
              >
                <template v-if="drawerWidth === 64">
                  <v-list-item-icon>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          v-text="subItem.meta.icon"
                        />
                      </template>
                      <span>{{
                        $vuetify.lang.t('$vuetify.menu.' + subItem.meta.title)
                      }}</span>
                    </v-tooltip>
                  </v-list-item-icon>
                </template>
                <template v-else>
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="
                        $vuetify.lang.t('$vuetify.menu.' + subItem.meta.title)
                      "
                    />
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-group>
          </template>
          <template v-else>
            <v-list-item
              :key="key"
              :to="item.path"
              v-show="!item.meta.hiddenInMenu"
              v-if="validateAccess(item.meta)"
            >
              <v-list-item-icon>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" v-text="item.meta.icon" />
                  </template>
                  <span>{{
                    $vuetify.lang.t('$vuetify.menu.' + item.meta.title)
                  }}</span>
                </v-tooltip>
              </v-list-item-icon>
              <v-list-item-content v-if="drawerWidth !== 64">
                <v-list-item-title
                  v-text="$vuetify.lang.t('$vuetify.menu.' + item.meta.title)"
                />
              </v-list-item-content>
              <v-list-item-action v-if="item.meta.new">
                <v-icon color="green">mdi-new-box </v-icon>
              </v-list-item-action>
            </v-list-item>
          </template>
        </template>
      </v-list>
    </div>

    <!-- <template v-slot:append>
      <div class="grey lighten-3">
        <template v-if="drawerWidth === 64">
          <div class="d-flex">
            <v-btn
              width="64"
              icon
              tile
              @click="handleDrawerCollapse"
              class="mx-auto"
            >
              <v-icon>mdi-arrow-collapse-right</v-icon>
            </v-btn>
          </div>
        </template>
        <template v-else>
          <div class="d-flex">
            <v-spacer />
            <v-btn icon tile @click="handleDrawerCollapse" class="mr-2">
              <v-icon>mdi-arrow-collapse-left</v-icon>
            </v-btn>
          </div>
        </template>
      </div>
    </template> -->
  </v-navigation-drawer>
</template>
<script>
import { protectedRoute as routes } from '@/router/routes';
import { mapGetters } from 'vuex';

export default {
  name: 'AppDrawer',
  components: {},
  props: {
    expanded: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      mini: false,
      drawerWidth: 256,
      drawer: true,
      scrollSettings: {
        maxScrollbarLength: 160
      },
      sponsor: {
        href: 'https://www.theopticalfiber.com/',
        src: 'https://www.theopticalfiber.com/logo/logo.svg',
        srcMini: 'https://www.theopticalfiber.com/logo/logo_mini.png'
      }
    };
  },

  computed: {
    ...mapGetters('auth', ['getUser']),
    computeMenu() {
      return routes[0].children;
    }
  },
  created() {},

  methods: {
    handleDrawerCollapse() {
      this.drawerWidth = this.drawerWidth === 256 ? 64 : 256;
    },
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    validateAccess(meta) {
      const roles = meta ? meta.roles : undefined
      if (!roles) {
        return true
      }
      return roles.includes(this.getUser.rol)
    }
  }
};
</script>

<style lang="sass" scoped>
.app-drawer
  overflow: hidden !important
  &__inner
    height: calc(100vh - 48px)
    overflow-y: scroll
  .drawer-menu--scroll
    height: calc(100vh - 48px)
    overflow: auto
</style>
