import AuthService from '@/util/auth.service';
import router from '@/router';

const defaulState = {
  token: null,
  user: {},
};

const getters = {
  getAccessToken: (state) => state.token,
  getUser: (state) => state.user,
};

const actions = {
  login({ commit, dispatch }, { email, password }) {
    return new Promise((resolve, reject) => {
      AuthService({
        url: '/auth/login',
        method: 'post',
        data: {
          email,
          password,
        },
      }).then((resp) => {
        commit('SET_ACCESS_TOKEN', resp.token);
        commit('SET_LOGIN', resp.user);
        dispatch('fetchProfile');
        resolve()
      }).catch(reject);
    })
  },
  logout({ commit }) {
    commit('SET_ACCESS_TOKEN', null);
    commit('SET_LOGIN', {});
    router.push('/auth/login');
  },
  // get current login user info
  fetchProfile() {
    // return AuthService({
    //   url: '/me',
    //   method: 'get'
    // }).then(({ data }) => {
    //   commit('SET_LOGIN_PROFILE', data);
    // });
  },
};

const mutations = {
  SET_LOGIN(state, user) {
    state.user = user;
  },
  SET_ACCESS_TOKEN(state, token) {
    state.token = token;
  },
  SET_LOGIN_PROFILE(state, payload) {
    state.user = payload;
  },
};

export default {
  namespaced: true,
  defaulState,
  getters,
  actions,
  mutations,
};
