/* eslint-disable linebreak-style */
import es from 'vuetify/es5/locale/es';

export default {
  label: 'Español',
  login: {
    login: 'Iniciar Sesión',
    email: 'Correo electrónico',
    password: 'Contraseña',
  },
  menu: {
    home: 'Home',
    users: 'Usuarios',
    videos: 'Videos',
    'Clases Base': 'Clases Base',
    'Tipos Base': 'Tipos Base',
    'Tipos de Objeto': 'Tipos de Objeto',
    Clientes: 'Clientes',
  },
  users: {
    name: 'Usuario',
    plural_name: 'Usuarios',
    create: 'Nuevo usuario',
    update: 'Actualizar usuario',
    read: 'Ver usuario',
    delete: 'Eliminar usuario',
    delete_confirm: 'Estás seguro de eliminar el usuario?',
  },
  baseClasses: {
    name: 'Clase Base',
    plural_name: 'Clases Base',
    create: 'Nueva Clase Base',
    update: 'Actualizar Clase Base',
    read: 'Ver Clase Base',
    delete: 'Eliminar Clase Base',
    delete_confirm: 'Estás seguro de eliminar la Clase Base?',
  },
  baseTypes: {
    name: 'Tipo Base',
    plural_name: 'Tipos Base',
    create: 'Nueva Tipo Base',
    update: 'Actualizar Tipo Base',
    read: 'Ver Tipo Base',
    delete: 'Eliminar Tipo Base',
    delete_confirm: 'Estás seguro de eliminar el Tipo Base?',
  },
  objectTypes: {
    name: 'Tipo de Objeto',
    plural_name: 'Tipos de Objeto',
    create: 'Nueva Tipo de Objeto',
    update: 'Actualizar Tipo de Objeto',
    read: 'Ver Tipo de Objeto',
    delete: 'Eliminar Tipo de Objeto',
    delete_confirm: 'Estás seguro de eliminar el Tipo de Objeto?',
  },
  clients: {
    name: 'Cliente',
    plural_name: 'Clientes',
    create: 'Nuevo Cliente',
    update: 'Actualizar Cliente',
    read: 'Ver Cliente',
    delete: 'Eliminar Cliente',
    delete_confirm: 'Estás seguro de eliminar el Cliente?',
  },
  rule: {
    required: 'Campo requerido',
  },
  ...es,
};
