import Vue from "vue";
import VueResource from "vue-resource";
import { VueMaskDirective } from "v-mask";
import moment from 'moment'
import 'moment/locale/es'
import BaseSnackbar from "@/components/base/Snackbar.vue"
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

import "./main.css";

Vue.directive("mask", VueMaskDirective);
Vue.component("BaseSnackbar", BaseSnackbar)
Vue.use(VueResource);

moment.locale("es");

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

// eslint-disable-next-line no-extend-native
String.prototype.toSlug = function () {
  const from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç";
  const to = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc";
  const mapping = {};

  for (let i = 0, j = from.length; i < j; i++) mapping[from.charAt(i)] = to.charAt(i);

  const normalize = function (str) {
    const ret = [];
    for (let i = 0, j = str.length; i < j; i++) {
      const c = str.charAt(i);
      // eslint-disable-next-line no-prototype-builtins
      if (mapping.hasOwnProperty(str.charAt(i))) ret.push(mapping[c]);
      else ret.push(c);
    }
    return ret
      .join("")
      .replace(/[^-A-Za-z0-9]+/g, "-")
      .toLowerCase();
  };
  return normalize(this);
};
